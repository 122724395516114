<template>
  <div>
    <portal to="subheader_actions">
      <b-button :disabled="sending" @click="onSubmit" class="mr-2">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div class="justify-content-center">
              <form class="full" id="formSubproject" ref="formSubproject">
                <b-alert
                  show
                  variant="danger"
                  v-bind:key="error"
                  v-for="error in errors"
                  >{{ error }}</b-alert
                >
                <trazo-form-input
                  id="input-first_name"
                  v-model="first_name"
                  label="Nombre"
                  name="first_name"
                  :required="true"
                  labelPosition="top"
                  type="text"
                />
                <trazo-form-input
                  id="input-last_name"
                  v-model="last_name"
                  label="Apellido Paterno"
                  name="last_name"
                  :required="true"
                  labelPosition="top"
                  type="text"
                />
                <trazo-form-input
                  id="input-last_name_2"
                  v-model="last_name_2"
                  label="Apellido Materno"
                  name="last_name_2"
                  :required="true"
                  labelPosition="top"
                  type="text"
                />
                <trazo-form-label-slot
                  label="Imagen de firma"
                  labelPosition="top"
                >
                  <trazo-image-crop
                    v-on:showModal="onShowModal"
                    v-on:selectImage="selectPhoto"
                  />
                  <img
                    class="mt-3"
                    style="max-width: 300px;"
                    v-if="imageToSend && !showImage"
                    :src="imageToSend.dataUrl"
                  />
                  <img
                    class="mt-3"
                    v-if="scanned_signature && !imageToSend && !showImage"
                    :src="scanned_signature"
                  />
                </trazo-form-label-slot>
                <h3>Información de inicio de sesión</h3>
                <trazo-form-input
                  id="input-email"
                  v-model="email"
                  label="Correo electrónico"
                  name="email"
                  :required="true"
                  labelPosition="top"
                  type="email"
                />
                <trazo-form-input
                  id="input-password"
                  v-model="password"
                  label="Password"
                  name="password"
                  :required="true"
                  labelPosition="top"
                  type="password"
                />
              </form>
            </div>
          </b-card-text>
          <template v-slot:footer> </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
import TrazoImageCrop from "@/view/content/TrazoImageCrop";
import ImageCompressor from "compressorjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

function initialState() {
  return {
    showImage: false,
    textModal: "NUEVO USUARIO",
    username: "",
    scanned_signature: "",
    first_name: "",
    last_name: "",
    last_name_2: "",
    email: "",
    password: "",
    driver_license_type: "-",
    license_number: "",
    id: "",
    edit: false,
    errors: [],
    sending: false,
    driver_license_types: [
      { value: "-", text: "NO POSEE" },
      { value: "A-I", text: "A-I" },
      { value: "A-IIa", text: "A-IIa" },
      { value: "A-IIb", text: "A-IIb" },
      { value: "A-IIIa", text: "A-IIIa" },
      { value: "A-IIIb", text: "A-IIIb" },
      { value: "A-IIIc", text: "A-IIIc" },
      { value: "B-I", text: "B-I" },
      { value: "B-IIa", text: "B-IIa" },
      { value: "B-IIb", text: "B-IIb" },
      { value: "B-IIc", text: "B-IIc" }
    ],
    imageToSend: null
  };
}

export default Vue.component("modal-form-user", {
  data() {
    return initialState();
  },
  props: ["objForm", "isOpen", "selectorToggle"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    }
  },
  methods: {
    onShowModal(event) {
      this.showImage = event;
    },
    selectPhoto(image) {
      // let data = new FormData();
      this.imageToSend = image;
      new ImageCompressor(image.blob, {
        convertSize: Infinity,
        maxWidth: 300,
        maxHeight: 100,
        minWidth: 300,
        minHeight: 100,
        quality: 0.6,

        success: result => {
          console.log("new file", result);
          this.imageToSend.blob = result;
        },
        error(err) {
          console.log(err.message);
        }
      });
    },
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        let objeSaved = new FormData();
        if (this.imageToSend) {
          objeSaved.append(
            "scanned_signature",
            this.imageToSend.blob,
            this.imageToSend.name
          );
        }
        objeSaved.append("first_name", this.first_name.trim());
        objeSaved.append("last_name", this.last_name.trim());
        objeSaved.append("last_name_2", this.last_name_2.trim());
        objeSaved.append("email", this.email.trim());

        if (this.password.trim().length !== 0) {
          objeSaved.append("password", this.password.trim());
        }

        this.errors = [];
        this.sending = true;
        ApiService.post(`api/user/profile/edit/`, objeSaved)
          .then(() => {
            this.$bvToast.toast(`Se ha actualizado tu perfil correctamente`, {
              title: `Éxito`,
              variant: "success",
              solid: true
            });
            this.setData();
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach(object => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$bvToast.toast("Ha ocurrido un error", {
              title: `Error`,
              variant: "danger",
              solid: true
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$bvToast.toast("Por favor complete los campos correctamente.", {
          title: `Error`,
          variant: "danger",
          solid: true
        });
      });
    },
    async setData() {
      try {
        const { data } = await ApiService.get("/api/user/me");
        this.id = data.id;
        this.username = data.username;
        this.first_name = data.first_name;
        this.scanned_signature = data.scanned_signature;
        this.last_name = data.last_name;
        this.last_name_2 = data.last_name_2;
        this.driver_license_type = data.driver_license_type;
        this.license_number = data.license_number;
        this.email = data.email;
        this.textModal = "EDITAR USUARIO";
        this.edit = true;
      } catch (error) {
        console.log("error", error);
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      let passwordValidator = {};
      let licenseValidator = {};
      if (this.password.trim().length !== 0) {
        passwordValidator = {
          password: {
            validators: {
              stringLength: {
                min: 8,
                message: "La contraseña debe tener un minimo de 8 caracteres."
              },
              notEmpty: {
                message: "La contraseña es obligatorio"
              }
            }
          }
        };
      }

      this.fv = formValidation(formSubproject, {
        fields: {
          last_name: {
            validators: {
              notEmpty: {
                message: "El Apellido Paterno es obligatorio"
              }
            }
          },
          email: {
            validators: {
              notEmpty: {
                message: "El correo electrónico es obligatorio"
              },
              emailAddress: {
                message: "No es un correo electrónico válido."
              }
            }
          },
          last_name_2: {
            validators: {
              notEmpty: {
                message: "El apellido Materno es obligatorio"
              }
            }
          },

          first_name: {
            validators: {
              notEmpty: {
                message: "El nombre es obligatorio"
              }
            }
          },
          ...licenseValidator,
          ...passwordValidator
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mentenimiento" },
      { title: "Actualizar Perfil" }
    ]);
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    TrazoImageCrop,
    TrazoFormLabelSlot
  }
});
</script>
